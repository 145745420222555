
export const environment = {
  production: true,
  docsBaseUrl:
    'https://docs.verosoftdesign.com/docs/the-asset-guardian-technician-portal',
  tagPageLink: '/technician-portal-navigation-bar-pages',
  supportLink: '/about-tag-portal',
  forumLink: '/about-tag-portal',
  // Variables that will get replaced by the CI/CD pipeline
  baseWS: 'https://portal-api.theassetguardian.com/api',
  version: 'Prod 10.5.1',
  enableTracking: true,
  trackingEnvironment: 'development',
  skipLogin: false,
};
